export default class MommomEnv {
    static get apiHost(): string {
        return process.env.REACT_APP_API_HOST || ''
    }

    static get momstouchApiHost(): string {
        return process.env.REACT_APP_MOMSTOUCH_API_HOST || ''
    }

    static get momnoljaApiHost(): string {
        return process.env.REACT_APP_MOMNOLJA_API_HOST || ''
    }

    static get domain(): string {
        return process.env.REACT_APP_DOMAIN || ''
    }

    static get isProduction(): boolean {
        return process.env.REACT_APP_ENV === 'production'
    }

    static get isSajangnim(): boolean {
        return process.env.REACT_APP_SUBJECT === 'SAJANGNIM'
    }

    static get kakaoJsKey(): string {
        return process.env.REACT_APP_KAKAO_JS_KEY || ''
    }
}
