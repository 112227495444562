import axios from 'axios'
import { auth } from './auth'
import * as Sentry from '@sentry/react'
import MommomEnv from './MommomEnv'

export const momstouchAxios = axios.create()

momstouchAxios.interceptors.request.use(
    async (config) => {
        const headers: { Authorization?: string } = {}

        const idToken = await auth.getIdToken()
        if (idToken) {
            headers.Authorization = `Bearer ${idToken}`
        }

        const newConfig = {
            baseURL: MommomEnv.momstouchApiHost,
            headers,
        }
        return Object.assign({}, config, newConfig)
    },
    (error) => {
        console.log(error)
    },
)

momstouchAxios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error) {
            console.log('momstouchAxiosError', error)
            Sentry.captureException(error)
        }
    },
)

export const apiAxios = axios.create()

apiAxios.interceptors.request.use(
    async (config) => {
        const headers: { Authorization?: string } = {}

        const idToken = await auth.getIdToken()
        if (idToken) {
            headers.Authorization = `Bearer ${idToken}`
        }

        const newConfig = {
            baseURL: MommomEnv.apiHost,
            headers,
        }
        return Object.assign({}, config, newConfig)
    },
    (error) => {
        console.log(error)
    },
)

export const momnoljaAxios = axios.create()

momnoljaAxios.interceptors.request.use(
    async (config) => {
        const headers: { Authorization?: string } = {}

        const idToken = await auth.getIdToken()
        if (idToken) {
            headers.Authorization = `Bearer ${idToken}`
        }

        const newConfig = {
            baseURL: MommomEnv.momnoljaApiHost,
            headers,
        }
        return Object.assign({}, config, newConfig)
    },
    (error) => {
        console.log(error)
    },
)

momnoljaAxios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error) {
            return error.response
        }
    },
)

apiAxios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error) {
            const { response } = error
            if (response && response.status === 403) {
                if (
                    window.confirm(
                        '로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?',
                    )
                ) {
                    window.location.href = '/login'
                }
            } else {
                Sentry.captureException(error)
            }
            console.log('apiAxiosError', error)
        }
    },
)
