import { initializeApp } from 'firebase/app'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import MommomEnv from './MommomEnv'

const firebaseConfig = {
    apiKey: 'AIzaSyD5mCsZPMmWu8zRr7Ck516z7BS7Hy9LY1k',
    authDomain: 'mom-mom-dev.firebaseapp.com',
    projectId: 'mom-mom-dev',
    storageBucket: 'mom-mom-dev.appspot.com',
    messagingSenderId: '1060146505600',
    appId: '1:1060146505600:web:d5bc32ad1bb160aadd4365',
    measurementId: 'G-14H17T00Q1',
}

const firebaseConfigProd = {
    apiKey: 'AIzaSyCpD4zcVjBFmVAD91lbQ5Wb-PNgnA4WpQU',
    authDomain: 'mom-mom-hotdeal.firebaseapp.com',
    projectId: 'mom-mom-hotdeal',
    storageBucket: 'mom-mom-hotdeal.appspot.com',
    messagingSenderId: '1035613186208',
    appId: '1:1035613186208:web:b2f1918800478e504101c5',
    measurementId: 'G-7EEGT91YDW',
}

const firebaseApp = initializeApp(
    MommomEnv.isProduction ? firebaseConfigProd : firebaseConfig,
)

export const firebaseAuth = getAuth(firebaseApp)
export const signIn = (customToken: string) =>
    signInWithCustomToken(firebaseAuth, customToken)
