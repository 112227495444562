import React from 'react'
import ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import './index.css'
import App from 'containers/App'
import reportWebVitals from './reportWebVitals'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import MommomEnv from './services/MommomEnv'

Sentry.init({
    dsn: MommomEnv.isProduction
        ? 'https://283891f814e5417bba583b1c9988b778@o559033.ingest.sentry.io/4504960512950272'
        : undefined,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <RecoilRoot>
        <App />
    </RecoilRoot>,
    // <React.StrictMode>
    // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
