import { atom } from 'recoil'
import { User } from 'models/UserModel'

export const isLoginState = atom<boolean | null>({
    key: 'isLoginState',
    default: null,
})
export const userState = atom<User | null>({
    key: 'userState',
    default: null,
})
