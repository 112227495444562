import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import { setUser as setSentryUser } from '@sentry/react'
import { useAuth } from 'services/auth'
import { apiAxios } from 'services/axios'
import { Popover } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { useRecoilState, useRecoilValue } from 'recoil'
import { isLoginState, userState } from 'containers/App/state'
import { User } from 'models/UserModel'
import mmLogo from 'assets/images/mm-text-logo.svg'

export default function Header() {
    const auth = useAuth()
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const isLoginPage = pathname.includes('login')
    const isLogin = useRecoilValue(isLoginState)
    const [user, setUser] = useRecoilState(userState)

    useEffect(() => {
        if (isLogin === false && !isLoginPage) navigate('/login')
    }, [isLogin, pathname])

    useEffect(() => {
        if (isLogin) {
            apiAxios.get<User>('/me').then((resp) => {
                if (resp.status === 200) {
                    setUser(resp.data)
                    const { id, email, nickname } = resp.data
                    setSentryUser({ id, email, nickname })
                }
            })
        }
    }, [isLogin])

    function logout() {
        if (window.confirm('로그아웃 하시겠습니까?')) {
            auth.logout()
            setUser(null)
        }
    }

    const settingContent = (
        <PopoverContent>
            <p onClick={logout}>로그아웃</p>
        </PopoverContent>
    )

    function onClickMmLogo() {
        navigate('/')
    }

    return (
        <>
            <Container>
                <img src={mmLogo} alt={'mmLogo'} onClick={onClickMmLogo} />
                {user && !isLoginPage && (
                    <Popover
                        placement="bottomRight"
                        content={settingContent}
                        trigger="click"
                    >
                        <Logined>
                            <img
                                className={'profile-img'}
                                src={user.profileImageThumb}
                                alt={user.nickname}
                            />
                            <SettingOutlined className={'setting-icon'} />
                        </Logined>
                    </Popover>
                )}
            </Container>
            <EmptyBox></EmptyBox>
        </>
    )
}

const Container = styled.div`
    position: fixed;
    padding: 18px 24px;
    background-color: var(--grey10);
    width: 100%;
    max-width: 768px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;

    img {
        cursor: pointer;
    }
`

const Logined = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;

    .profile-img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 1px solid var(--grey7);
    }

    .setting-icon svg {
        position: absolute;
        right: -5px;
        bottom: 0;
        width: 14px;
        padding: 2px;
        background-color: var(--grey10);
        border-radius: 50%;
    }
`

const PopoverContent = styled.div`
    p {
        font-size: 14px;
        cursor: pointer;
    }

    p:hover {
        font-weight: 700;
    }
`

const EmptyBox = styled.div`
    height: 50px;
`
