import { firebaseAuth, signIn } from './firebase'
import { User as FirebaseUser } from 'firebase/auth'
import axios from 'axios'
import { setUser as setSentryUser } from '@sentry/react'
import MommomEnv from './MommomEnv'

export class Auth {
    async login(kakaoCode: string) {
        const kakaoResp = await axios.post<{ access_token: string }>(
            'https://kauth.kakao.com/oauth/token',
            {
                grant_type: 'authorization_code',
                client_id: MommomEnv.isProduction
                    ? '8dc43f83a6874bbacc610a8ee43c6712'
                    : 'b5e7821621402aa250c7a2ee9592425b',
                redirect_uri: `${MommomEnv.domain}/login/kakao`,
                code: kakaoCode,
            },
            {
                headers: {
                    'Content-type':
                        'application/x-www-form-urlencoded;charset=utf-8',
                },
            },
        )

        const kakaoToken = kakaoResp.data.access_token

        const url = `${MommomEnv.apiHost}/auth/login`
        const { data: result } = await axios.post<MommomLoginResult>(url, {
            token: kakaoToken,
        })

        if (result.status !== 'DONE') {
            throw Error('맘맘 회원만 사용 가능합니다.')
        }

        return this.signInCustomToken(result.firebaseToken)
    }

    logout() {
        firebaseAuth.signOut().then()
    }

    private async signInCustomToken(customToken: string) {
        return signIn(customToken)
    }

    onAuthChange = (callback: (user: FirebaseUser | null) => void) => {
        //로그아웃된 상태면 user는 null이다.
        firebaseAuth.onAuthStateChanged(
            (user) => {
                callback(user)
            },
            (error) => {
                console.error(error)
            },
        )
    }

    getUser = () => {
        return firebaseAuth.currentUser
    }

    async getIdToken() {
        return new Promise<string | null>((resolve, reject) => {
            firebaseAuth.onIdTokenChanged(
                (user) => {
                    if (user) {
                        resolve(user.getIdToken())
                    } else {
                        resolve(null)
                        setSentryUser(null)
                    }
                },
                (error) => reject(error),
            )
        })
    }
}

interface MommomLoginResult {
    firebaseToken: string
    status: 'DONE' | 'NEW'
    uid: string
}

export const auth = new Auth()
export function useAuth() {
    return auth
}
