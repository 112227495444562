import React, { lazy, Suspense, useEffect } from 'react'
import styled from 'styled-components'
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
} from 'react-router-dom'
import { User as FirebaseUser } from '@firebase/auth'
import MommomEnv from 'services/MommomEnv'
import { useAuth } from 'services/auth'
import { useSetRecoilState } from 'recoil'
import { isLoginState } from './state'
import Header from 'components/common/Header'
import ScrollToTop from './ScrollToTop'
const ReportList = lazy(() => import('containers/ReportList'))
const ReviewerReport = lazy(() => import('containers/AddReviewerReport'))
const OwnerReport = lazy(() => import('containers/AddOwnerReport'))
const CompleteReport = lazy(() => import('containers/CompleteReport'))
const Login = lazy(() => import('containers/Login'))
const KakaoLogin = lazy(() => import('containers/Login/KakaoLogin'))
const Error = lazy(() => import('containers/Error'))
const MembershipVerification = lazy(
    () => import('containers/MembershipVerification'),
)

function HeaderWrapper() {
    const location = useLocation()
    const hideHeader = location.pathname.startsWith('/membership/verify/')

    return hideHeader ? null : <Header />
}

function App() {
    const auth = useAuth()
    const setIsLogin = useSetRecoilState(isLoginState)

    useEffect(() => {
        auth.onAuthChange((firebaseUser: FirebaseUser | null) => {
            setIsLogin(!!firebaseUser)
        })

        document.addEventListener('visibilitychange', function () {
            setIsLogin(!!auth.getUser())
        })
    }, [])

    return (
        <Wrapper>
            <Container>
                <Router>
                    <ScrollToTop />
                    <HeaderWrapper />
                    <Suspense fallback={<div></div>}>
                        <Routes>
                            <Route path="/" element={<ReportList />} />
                            {MommomEnv.isSajangnim ? (
                                <>
                                    <Route
                                        path="/membership/verify/:id"
                                        element={<MembershipVerification />}
                                    />
                                    <Route
                                        path="/reports/owner/add"
                                        element={<OwnerReport />}
                                    />
                                    <Route
                                        path="/reports/owner/add/:id"
                                        element={<OwnerReport />}
                                    />
                                    <Route
                                        path="/membership/verify/:id"
                                        element={<OwnerReport />}
                                    />
                                </>
                            ) : (
                                <>
                                    <Route
                                        path="/reports/reviewer/add"
                                        element={<ReviewerReport />}
                                    />
                                    <Route
                                        path="/reports/reviewer/add/:id"
                                        element={<ReviewerReport />}
                                    />
                                </>
                            )}
                            <Route
                                path="/reports/complete"
                                element={<CompleteReport />}
                            />
                            <Route path="/login" element={<Login />} />
                            <Route
                                path="/login/kakao"
                                element={<KakaoLogin />}
                            />
                            <Route path="*" element={<Error />} />
                        </Routes>
                    </Suspense>
                </Router>
            </Container>
        </Wrapper>
    )
}
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: scroll;
`

const Container = styled.div`
    position: relative;
    background-color: var(--grey10);
    min-height: 100vh;
    width: 100%;
    height: 100%;

    @media screen and (min-width: 768px) {
        width: 768px;
        max-width: 768px;
    }
`

export default App
